import { Injectable } from '@angular/core';

import { catchError, Observable, of, tap } from 'rxjs';
import { UserService } from '../services/user.service';
import { UserState } from '../store/user/user.store';
import { Store } from '@ngrx/store';
import { loginAttempted, updateUser } from '../store/user/user.actions';
import { User } from '../models/User';

@Injectable({
  providedIn: 'root',
})
export class UserProfileGuard  {
  constructor(
    private userService: UserService,
    private store: Store<UserState>
  ) {}

  canActivate(): Observable<any> {
    return this.userService.getMyProfile().pipe(
      tap((user: User) => {
        this.store.dispatch(loginAttempted());
      }),
      catchError(() => {
        this.store.dispatch(loginAttempted());
        return of({});
      })
    );
  }
}
