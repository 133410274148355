import {Injectable} from '@angular/core';
import {catchError, Observable, of} from 'rxjs';
import {CurrencyService} from "../services/currency.service";
import {Nullable} from "../common/types";
import {CurrencyRates, defaultCurrencyRatesValues} from "../models/CurrencyRates";

@Injectable({
    providedIn: 'root',
})
export class CampaignPageCurrencyResolver {
    constructor(private currencyService: CurrencyService,) {
    }

    resolve(): Observable<CurrencyRates> {
        return this.currencyService.get().pipe(catchError(() => of(defaultCurrencyRatesValues))
        );
    }
}
