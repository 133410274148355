import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map, Observable} from 'rxjs';
import {ApiResponse} from '../models/ApiResponse';
import {CurrencyRates} from "../models/CurrencyRates";

@Injectable({
    providedIn: 'root',
})
export class CurrencyService {
    campaignsBaseUrl = `//${environment.domain}/${environment.apiPrefix}/currency`;

    constructor(private httpClient: HttpClient) {
    }

    get(): Observable<CurrencyRates> {
        return this.httpClient
            .get<ApiResponse<CurrencyRates>>(`${this.campaignsBaseUrl}`)
            .pipe(map(response => response.data!!));
    }
}
