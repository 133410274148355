export interface CurrencyRates {
    base: string,
    usd: number,
    eur: number
}

export const defaultCurrencyRatesValues: CurrencyRates = {
    base: "ILS",
    usd: 4,
    eur: 4
}
